<script>
import isEmpty from 'lodash-es/isEmpty'
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useAddresses } from '/~/composables/addresses'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import PointsConverter from './points-converter.vue'
import PointsPartner from './points-partner.vue'

export default {
  name: 'fly-points-transfer-form',
  components: {
    PointsConverter,
    PointsPartner,
    BaseLoader,
    BaseButton,
    BaseCheckbox,
  },
  setup() {
    const { points, fetchPaymentMethods } = usePaymentMethods()
    const { addresses, defaultAddress, firstAddress } = useAddresses()
    const route = useRoute()
    const {
      transferPoints,
      fetchAccounts,
      getAccountById,
      showTerms,
      termsLabel,
      selectedPartner,
    } = usePointsPrograms()

    const account = computed(() => {
      return {
        icon: selectedPartner.value.icon,
        ...getAccountById(route.query.accountId),
      }
    })

    if (account.value === undefined) {
      fetchAccounts()
    }

    return {
      points,
      fetchPaymentMethods,
      addresses,
      defaultAddress,
      firstAddress,
      transferPoints,
      account,
      showTerms,
      termsLabel,
      selectedPartner,
    }
  },
  data() {
    return {
      form: {
        points: null,
        amount: null,
      },
      address: null,
      agree: false,
      progress: false,
      valid: true,
      calculating: false,
    }
  },
  computed: {
    canSubmit() {
      const { amount } = this.form

      return (
        !this.calculating &&
        Boolean(this.account?.raw.accountNumber) &&
        Boolean(amount) &&
        this.agree
      )
    },
    partnerType() {
      return this.account?.type
    },
    hasSelectedPartner() {
      return !isEmpty(this.account)
    },
  },
  mounted() {
    this.getAddress()
  },
  methods: {
    getAddress(timeout = 0) {
      if (!this.account) return
      setTimeout(() => {
        const address = this.defaultAddress
          ? this.defaultAddress
          : this.firstAddress

        if (!address) {
          modal.show('new-address', {
            props: {
              data: {
                default:
                  Array.isArray(this.addresses) && this.addresses.length === 0,
              },
              type: 'create',
            },
          })
        } else {
          this.address = address
        }
      }, timeout)
    },
    onPointsChange(props) {
      if (!this.address) {
        this.getAddress()
      } else {
        this.form.eWalletPoints = props.valueFrom
        this.form.flyPoints = props.valueTo
        this.form.amount = props.amount
      }
    },
    onValid(valid) {
      this.valid = valid
    },
    onSubmit() {
      this.progress = true

      const { amount, eWalletPoints, flyPoints } = this.form

      const referenceLabel = this.account
        ? `${this.account.firstName} ${this.account.type}`
        : null

      const payload = {
        paymentSources: [
          {
            paymentMethodId: this.points[0].id,
            amount,
          },
        ],
        pointsPrograms: [
          {
            externalId: this.account.id,
            programType: this.partnerType,
            amount,
          },
        ],
        addressId: `${this.address.id}`,
        reference: [referenceLabel, this.account?.raw.accountNumber]
          .filter(Boolean)
          .join(' - ')
          .toUpperCase(),
      }

      this.transferPoints({
        payload,
        form: { eWalletPoints, flyPoints, partner: this.account.raw },
      })
        .then((value) => {
          this.$router.push({
            name: 'fly-points-transfer-successful',
            params: { number: value.data?.number },
          })
          this.fetchPaymentMethods()
        })
        .catch((error) => {
          modal.show('points-transfer-error', {
            props: {
              message: error.message,
            },
          })
        })
        .finally(() => {
          this.progress = false
        })
    },
    converterClickHandler() {
      if (!this.address) {
        this.getAddress()
      }
    },
    onPartnerClick(partner) {
      this.$router.push({
        name: 'fly-business',
        query: { type: partner.type },
      })
    },
  },
}
</script>

<template>
  <div v-if="account" class="mx-auto flex h-full max-w-xl flex-col">
    <div class="w-full">
      <span class="mb-4 block font-bold text-eonx-neutral-800">To</span>
      <points-partner
        :partner="account"
        :disabled="progress"
        :show-menu="false"
        @click="onPartnerClick"
      />
    </div>

    <div class="mt-6 w-full">
      <span class="mb-4 block font-bold text-eonx-neutral-800">Amount</span>

      <div
        class="space-y-4 divide-y divide-eonx-neutral-200 rounded-lg bg-white px-4 py-6 shadow-xs sm:rounded-lg sm:px-5 md:px-6 md:py-6"
      >
        <points-converter
          v-if="partnerType"
          :calculating.sync="calculating"
          :disabled="progress"
          @converted="onPointsChange"
          @valid="onValid"
          @click="converterClickHandler"
        />
        <div data-testid="points-rounding-description" class="pt-4 text-sm">
          The final points debited for {{ selectedPartner.programCurrency }} may
          vary by up to 1 point due to rounding.
        </div>
      </div>
    </div>

    <div v-if="hasSelectedPartner" class="mt-10 flex flex-row sm:mt-8">
      <base-checkbox v-model="agree" :disabled="progress" name="agree" />

      <span class="leading-tight">
        <span class="text-default">I agree to</span>
        <span
          class="cursor-pointer pl-1 font-bold text-primary underline-offset-4"
          @click="showTerms"
        >
          <span class="hover:underline">{{ termsLabel }}</span>
        </span>
      </span>
    </div>

    <div class="mt-auto flex justify-center py-5 sm:py-8">
      <base-button
        class="w-full"
        :disabled="!canSubmit || progress || !valid"
        :loading="progress"
        @click="onSubmit"
      >
        Transfer
      </base-button>
    </div>
  </div>
  <base-loader v-else class="mx-auto w-full" />
</template>
